import _ from 'lodash';

const API = '/api/v1';

const buildEnvironment = () => {
  const _makeEnvironment = (PROTOCOL, HOST, PORT, CLIENT_ID, RECAPTCHA_SITEKEY) => {
    const forPATH = (PATH) => ({
      PROTOCOL,
      HOST,
      PORT,
      PATH,
    });
    return {
      OKTA: forPATH(`/okta${API}`),
      EVENTS: forPATH(`/events${API}`),
      DENODO: forPATH(`/denodo${API}`),
      PROCESSOR: forPATH(`/processor${API}`),
      SQL: forPATH(`/sql${API}`),
      PRINT: forPATH(`/print${API}`),
      HOST,
      PORT,
      CLIENT_ID,
      RECAPTCHA_SITEKEY,
    };
  };

  const ENVIRONMENTS = {
    LOCAL: {
      /** **************   Use QA environment?      ****************/
      ..._makeEnvironment(
        'https://',
        'skyan-qa.nepc.com',
        null,
        '0oaepzrcz01Olitb32p7',
        '6LchGIIeAAAAAC6D2Yqm10vcSuT2ggWuzImA5ZqJ'
      ),
      // SQL: {
      //   PROTOCOL: 'http://',
      //   HOST: 'localhost',
      //   PORT: 8231,
      //   PATH: API,
      // },
      // DENODO: {
      //     PROTOCOL: 'http://',
      //     HOST: 'localhost',
      //     PORT: 8232,
      //     PATH: API,
      // },
      // PRINT: {
      //   PROTOCOL: 'http://',
      //   HOST: 'localhost',
      //   PORT: 8207,
      //   PATH: '/api/v1',
      // },
      // PROCESSOR: {
      //   PROTOCOL: 'http://',
      //   HOST: 'localhost',
      //   PORT: 8230,
      //   PATH: '/api/v1',
      // },
      // OKTA: {
      //   PROTOCOL: 'http://',
      //   HOST: 'localhost',
      //   PORT: 8233,
      //   PATH: '/api/v1',
      // },
    },
    QA: {
      ..._makeEnvironment(
        'https://',
        'skyan-qa.nepc.com',
        null,
        '0oaemsar0f6ikymkv2p7',
        '6LchGIIeAAAAAC6D2Yqm10vcSuT2ggWuzImA5ZqJ'
      ),
    },
    UAT: {
      ..._makeEnvironment(
        'https://',
        'skyan-uat.nepc.com',
        null,
        '0oaepzwqxbZq7ZpOQ2p7',
        '6LctGoIeAAAAAKZcIKi8zawxYR7XRrGZYacfD1yg'
      ),
    },
    PROD: {
      ..._makeEnvironment(
        'https://',
        'skyan.nepc.com',
        null,
        '0oaepzvoh95dmaScI2p7',
        '6LcKG4IeAAAAANc1UvEW1agno5kyJkcGY8x3R7lu'
      ),
    },
  };

  const currentDomain = window && window.location && window.location.hostname;
  const domainMapping = {
    localhost: 'LOCAL',
    'skyan-qa.nepc.com': 'QA',
    'skyan-uat.nepc.com': 'UAT',
  };
  const env = domainMapping[currentDomain] || 'PROD';
  const generateUrls = (endpoints) =>
    _.mapValues(endpoints, (endpoint) => {
      if (endpoint && endpoint.HOST) {
        const PORT = endpoint.PORT ? ':' + endpoint.PORT : '';
        return {
          ...endpoint,
          URL: `${endpoint.PROTOCOL}${endpoint.HOST}${PORT}${endpoint.PATH}`,
        };
      } else {
        return endpoint;
      }
    });
  const withURLs = generateUrls(ENVIRONMENTS[env]);
  return {
    ENVIRONMENT: env,
    ...withURLs,
  };
};
const environmentDependentConstants = buildEnvironment();

export const Events = {
  LOGIN: 'ERM-Login',
  LOGOUT: 'ERM-Logout',
  OPEN_STUDY: 'ERM-Open study',
  CREATE_STUDY: 'ERM-Create study',
  SAVE_STUDY: 'ERM-Save study',
  CLONE_STUDY: 'ERM-Clone study',
  DELETE_STUDY: 'ERM-Delete study',
  PRINT_STUDY_PDF: 'ERM-Print study to PDF',
  EXPORT_STUDY_EXCEL: 'ERM-Export study to Excel',
  CHANGE_THE_COLUMNS_VIEW: 'ERM-Change the columns view',
  CHANGE_MEASURE_SCALE: 'ERM-Change measure scale',
  SELECT_CLIENT: 'ERM-Select client',
  CHANGE_ASSUMPTION: 'ERM-Change assumption',
  CALC_RISK_RETURNS: 'ERM-Calc risk and returns',
  UPDATE_MAINTENANCE_MODE: 'ERM-Update maintenance mode',
};

export const Constants = {
  // Environment
  ...environmentDependentConstants,

  // Page Views
  CARDS_VIEW: 'Cards',
  STUDY_VIEW: 'Study',
  TERMS_AND_CONDITIONS_VIEW: 'Terms and Conditions',
  SETTINGS_MENU_VIEW: 'Settings menu',
  NO_SKYAN_ACCESS: 'No Skyan access',

  OPERATING_REVENUE_TITLE: 'Operating Revenue (OR)',
  OPERATING_EXPENSE_TITLE: 'Operating Expense (OE)',

  POP_UP_TIME: 3000,

  // Other financial projections titles
  OTHER_FINANCIAL_PROJECTIONS_DEFS: {
    operatingEBIDA: {
      description: 'operatingEBIDA',
      info: '= (OI + DA + DIE)',
      key: '0',
      markedRow: false,
      readOnlyFrom: 'year0',
      title: 'Operating EBIDA (EBIDA)',
      children: [
        {
          description: 'depreciationAndAmortization',
          info: '',
          key: '0-0',
          markedRow: false,
          readOnlyFrom: '',
          title: 'Depreciation & Amortization (DA)',
          children: [],
        },
        {
          description: 'operatingIncome',
          info: '',
          key: '0-1',
          markedRow: false,
          readOnlyFrom: 'year0',
          title: 'Operating Income (OI)',
          children: [],
        },
        {
          description: 'debtInterestExpense',
          info: '',
          key: '0-2',
          markedRow: false,
          readOnlyFrom: '',
          title: 'Debt Interest Expense (DIE)',
          children: [],
        },
      ],
    },
    debtPrincipalPayments: {
      info: '',
      key: '1',
      markedRow: false,
      readOnlyFrom: '',
      title: 'Debt Principal Payments (DPP)',
      children: [],
    },
    longTermDebt: {
      info: '',
      key: '2',
      markedRow: false,
      readOnlyFrom: '',
      title: 'Long-Term Debt (LTD)',
      children: [],
    },
    unrestrictedAssets: {
      description: 'unrestrictedAssets',
      info: '',
      key: '3',
      markedRow: false,
      readOnlyFrom: 'year0',
      title: 'Unrestricted Assets (UA)',
      children: [],
    },
    capitalExpenditure: {
      info: '',
      key: '4',
      markedRow: false,
      readOnlyFrom: '',
      title: 'Capital Expenditure (CapEx)',
      children: [],
    },
  },

  // Financial Metrics titles
  FINANCIAL_METRICS_DEFS: {
    dailyExpenseFactor: {
      info: '= (OE - DA)/365',
      markedRow: false,
      measure: '',
      readOnly: true,
      title: 'Daily Expense Factor ($)',
    },
    operatingMargin: {
      info: '= (OI / OR)',
      markedRow: false,
      measure: '%',
      readOnly: true,
      title: 'Operating Margin',
    },
    operatingEBIDAMargin: {
      info: '= (EBIDA / OR)',
      markedRow: false,
      measure: '%',
      readOnly: true,
      title: 'Operating EBIDA Margin',
    },
    capexDepreciationAndAmortization: {
      info: '= (CapEx / DA)',
      markedRow: false,
      measure: '%',
      readOnly: true,
      title: 'CapEx/Depreciation & Amortization',
    },
    longTermDebtRate: {
      info: '= (DIE / LTD)',
      markedRow: false,
      measure: '%',
      readOnly: true,
      title: 'Long-Term Debt Rate',
    },
    longTermDebtCapitalization: {
      info: '= (LTD / UA)',
      markedRow: false,
      measure: '%',
      readOnly: true,
      title: 'Long-Term Debt/Capitalization',
    },
    maximumAnnualDebtServiceCoverage: {
      info: '= (OI / (DIE + DPP))',
      markedRow: false,
      measure: 'x',
      readOnly: true,
      title: 'Maximum Annual Debt Service Coverage',
    },
    totalDebtService: {
      info: '= (DIE + DPP)',
      markedRow: false,
      measure: '',
      readOnly: true,
      title: 'Total Debt Service ($)',
    },
  },

  ECONOMIC_SCENARIOS_NAMES: ['base case', 'depression', 'expansion', 'overextension', 'recession', 'stagflation'],

  // Other
  QUILL_TOOLBAR_MODULE: {
    toolbar: [
      [{ font: [] }, { size: [] }],
      [{ align: [] }, 'direction'],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ script: 'super' }, { script: 'sub' }],
      ['blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link'],
      ['clean'],
    ],
  },
};

export default Constants;
