import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';

import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Row } from 'primereact/row';

import { Button, FormGroup, Input, Label, UncontrolledTooltip } from 'reactstrap';

import { debounce } from 'lodash';
import {
  dispatchCalculatedCashFlows,
  dispatchCashFlowsData,
  dispatchShockTest,
  dispatchToExportStudy,
  dispatchUpdateStudy,
} from '../../actions';
import { Constants } from '../../services/Constants';
import { formatPrecisionNumber, isDeepEqual, slugToId } from '../../utils/utils';
import DefaultBox from '../DefaultBox';
import ManagementButton from '../ManagementButton';
import { CATEGORIES, _getSumByCategory, calcCashFlows } from './CalcCashFlows';
import { StyledCashFlows } from './StyledCashFlows';

const CashFlows = (props) => {
  const { calculatedCashFlows, measureScale, selectedStudy, calculatedChockTests } = props;

  const { partialSum, totalSum } = calculatedCashFlows;

  const { decimalPrecision } = selectedStudy.study;

  const getParsedNodes = (_cashFlows) => {
    const _sumByCategory = _getSumByCategory(_cashFlows);
    const parsedNodes = CATEGORIES.map((category) =>
      _cashFlows
        .filter((cashFlow) => cashFlow.categoryId === category.id)
        .reduce(
          (acc, cur, idx) => ({
            ...acc,
            children: [
              ...acc.children,
              {
                key: `${category.id}-${idx + 1}`,
                data: cur,
              },
            ],
          }),
          {
            categoryId: category.id,
            key: `${category.id}`,
            description: category.name,
            children: [],
            data: _sumByCategory.find((cashFlow) => cashFlow.categoryId === category.id),
          }
        )
    );
    return parsedNodes;
  };

  const SHOCKTEST_CATEGORIES = [{ id: 1, name: 'Shock Test' }];

  const getParsedShockNodes = (_shockTest) => {
    const _sumByCategory = _getSumByCategory(_shockTest);
    const parsedNodes = SHOCKTEST_CATEGORIES.map((category) =>
      _shockTest
        .filter((shockTest) => shockTest.categoryId === category.id)
        .reduce(
          (acc, cur, idx) => ({
            ...acc,
            children: [
              ...acc.children,
              {
                key: `${category.id}-${idx + 1}`,
                data: cur,
              },
            ],
          }),
          {
            categoryId: category.id,
            key: `${category.id}`,
            description: category.name,
            children: [],
            data: _sumByCategory.find((shockTest) => shockTest.categoryId === category.id),
          }
        )
    );
    return parsedNodes;
  };

  const debouncedDispatchCashFlows = useCallback(
    debounce((newCashFlows, parsedNodes) => {
      props.dispatchCashFlowsData(newCashFlows);
      props.dispatchToExportStudy({
        cashFlows: newCashFlows,
        categorizedCashFlows: parsedNodes,
      });
    }, 300),
    []
  );

  const [nodesState, setNodesState] = useState([]);
  const [cashFlows, setCashFlows] = useState([]);
  const [selectedNodeKeyState, setSelectedNodeKeyState] = useState(null);
  const [expandedKeys, setExpandedKeys] = useState(null);
  const [shockExpandedKeys, setShockExpandedKeys] = useState(['1']);
  const [isShowingShock, setIsShowingShock] = useState(false);
  const [shockNodesState, setShockNodesState] = useState([]);
  const [shockTest, setShockTest] = useState([]);
  const [shockTestToSave, setShockTestToSave] = useState({});

  useEffect(() => {
    if (!isDeepEqual(cashFlows, selectedStudy.cashFlows)) {
      const parsedNodes = getParsedNodes(selectedStudy.cashFlows);
      setNodesState(parsedNodes);
      setCashFlows(selectedStudy.cashFlows);
      const parsedShockNodes = getParsedShockNodes(calculatedChockTests);
      setShockNodesState(parsedShockNodes);
      setShockTest(calculatedChockTests);
      setShockTestToSave(selectedStudy.shockTests);
      props.dispatchToExportStudy({
        cashFlows: selectedStudy.cashFlows,
        categorizedCashFlows: parsedNodes,
      });
    }
  }, [selectedStudy.cashFlows]);

  useEffect(() => {
    if (!isDeepEqual(selectedStudy.calculatedCashFlows, calculatedCashFlows)) {
      props.dispatchCalculatedCashFlows(calculatedCashFlows);
      props.dispatchToExportStudy({
        calculatedCashFlows,
        partialCashFlowsSum: calculatedCashFlows.partialSum,
        totalCashFlowsSum: calculatedCashFlows.totalSum,
      });
    }
  }, [calculatedCashFlows]);

  useEffect(() => {
    if (!isDeepEqual(selectedStudy.shockTest, shockTest)) {
      props.dispatchShockTest(shockTest, shockTestToSave);
      props.dispatchToExportStudy(shockTest);
    }
  }, [shockTest, shockTestToSave]);

  const findNodeByKey = (_nodes, key) => {
    const path = key.split('-');
    let node;

    while (path.length) {
      const list = node ? node.children : _nodes;
      node = list[parseInt(path[0] - 1, 10)];
      path.shift();
    }
    return node;
  };

  const collapseAllKeys = () => setExpandedKeys(null);

  const expandAllKeys = () => setExpandedKeys(nodesState.map((row) => row.key));

  const setNewCashFlows = (newCashFlows) => {
    const parsedNodes = getParsedNodes(newCashFlows);
    setNodesState(parsedNodes);
    debouncedDispatchCashFlows(newCashFlows, parsedNodes);
    setCashFlows(newCashFlows);
  };

  const setNewShockTest = (newShockTest) => {
    const parsedNodes = getParsedShockNodes(newShockTest);
    setShockNodesState(parsedNodes);
    setShockTest(newShockTest);
  };

  const handleDispatchEditedCashFlow = (editedCashFlow, idx) => {
    const newCashFlows = [...cashFlows.slice(0, idx), editedCashFlow, ...cashFlows.slice(idx + 1)];
    setNewCashFlows(newCashFlows);
  };

  const handleDispatchEditedShockTest = (shockTestToCalc, shockTestToSave, idx) => {
    const newShockTest = [...shockTest.slice(0, idx), shockTestToCalc, ...shockTest.slice(idx + 1)];
    setNewShockTest(newShockTest);
    setShockTestToSave(shockTestToSave);
  };

  const handleNumberEditorValueChange = (rowData, value, colBodyOptions) => {
    const onlyNumbers = /\d+/g;
    if (onlyNumbers.test(value.toString())) {
      const cashFlowsIdx = cashFlows.indexOf(rowData.data);
      if (cashFlowsIdx < 0) return;
      const isOperatingExpense = rowData.data.description === Constants.OPERATING_EXPENSE_TITLE;
      const editedCashFlow = {
        ...rowData.data,
        [colBodyOptions.field]: isOperatingExpense ? -Math.abs(value) : value || 0,
      };
      handleDispatchEditedCashFlow(editedCashFlow, cashFlowsIdx);
    }
  };

  const handleShockNumberEditorValueChange = (rowData, value, colBodyOptions) => {
    const shockTestIdx = shockTest.indexOf(rowData.data);
    if (shockTestIdx < 0) return;
    const shockTestToCalc = {
      ...rowData.data,
      [colBodyOptions.field]: value || 0,
    };
    const item = {
      ...selectedStudy.shockTests?.[rowData.data.source],
      year0: shockTestToCalc.year0,
      year1: shockTestToCalc.year1,
      year2: shockTestToCalc.year2,
      year3: shockTestToCalc.year3,
      year4: shockTestToCalc.year4,
      year5: shockTestToCalc.year5,
    };
    const shockTestToSave = { ...selectedStudy.shockTests, [rowData.data.source]: item };
    handleDispatchEditedShockTest(shockTestToCalc, shockTestToSave, shockTestIdx);
  };

  const handleTextEditorValueChange = (rowData, value) => {
    const idx = cashFlows.indexOf(rowData.data);
    if (idx < 0 || rowData.data.fixed) return;
    const editedCashFlow = {
      ...rowData.data,
      description: value || '',
    };
    handleDispatchEditedCashFlow(editedCashFlow, idx);
  };

  const handleShockTextEditorUseValueChange = (rowData, value) => {
    const idx = shockTest.indexOf(rowData.data);
    if (idx < 0 || rowData.data.fixed) return;
    const shockTestToCalc = {
      ...rowData.data,
      isManualInput: value || '',
    };
    const item = {
      ...selectedStudy.shockTests?.[rowData.data.source],
      isManualInput: shockTestToCalc.isManualInput,
    };
    const shockTestToSave = { ...selectedStudy.shockTests, [rowData.data.source]: item };
    handleDispatchEditedShockTest(shockTestToCalc, shockTestToSave, idx);
  };

  const handleShockTextEditorValueChange = (rowData, value) => {
    if (Constants.ECONOMIC_SCENARIOS_NAMES.includes(value?.toLowerCase())) {
      NotificationManager.error('Shock test name cannot be equal to economic scenario categories');
    }

    const idx = shockTest.indexOf(rowData.data);
    if (idx < 0 || rowData.data.fixed) return;
    const shockTestToCalc = {
      ...rowData.data,
      description: value || '',
    };
    const item = {
      ...selectedStudy.shockTests?.[rowData.data.source],
      description: shockTestToCalc.description,
    };
    const shockTestToSave = { ...selectedStudy.shockTests, [rowData.data.source]: item };
    handleDispatchEditedShockTest(shockTestToCalc, shockTestToSave, idx);
  };

  const numberEditor = (rowData, colBodyOptions) => {
    if (rowData.children) {
      return (
        <div className="parent-row">
          {formatPrecisionNumber(rowData.data?.[colBodyOptions.field], decimalPrecision)}
        </div>
      );
    }
    return (
      <div>
        <InputNumber
          key={rowData.key}
          id={slugToId(`${rowData.data.description}-${colBodyOptions.field}`)}
          value={rowData.data?.[colBodyOptions.field] || 0}
          className={`table-editor child-row noPrint ${rowData.key}-${colBodyOptions.field}`}
          onChange={({ value }) => handleNumberEditorValueChange(rowData, value, colBodyOptions)}
          allowEmpty={false}
          inputMode="number"
          mode="decimal"
          locale="en-US"
          minFractionDigits={decimalPrecision}
          maxFractionDigits={decimalPrecision}
          onFocus={(event) => {
            event.target.selectionStart = 0;
            event.target.selectionEnd = event.target.value.length;
            setSelectedNodeKeyState(rowData.key);
          }}
        />
        <div className="printOnly">{formatPrecisionNumber(rowData.data?.[colBodyOptions.field], decimalPrecision)}</div>
      </div>
    );
  };

  const numberShockEditor = (rowData, colBodyOptions) => {
    const inputIsValid = !(rowData.data?.[colBodyOptions.field] > 5 || rowData.data?.[colBodyOptions.field] < -5);
    const invalidClassName = inputIsValid ? '' : 'p-invalid';
    return (
      <div>
        <InputNumber
          key={rowData.key}
          id={slugToId(`${rowData.data.description}-${colBodyOptions.field}`)}
          value={rowData.data?.[colBodyOptions.field] || null}
          className={`table-editor child-row noPrint ${rowData.key}-${colBodyOptions.field} ${invalidClassName}`}
          onChange={({ value }) => handleShockNumberEditorValueChange(rowData, value, colBodyOptions)}
          showButtons
          step={0.25}
          suffix=" %"
          placeholder="0.00 %"
          locale="en-US"
          minFractionDigits={2}
          maxFractionDigits={2}
        />
        <div className="printOnly">{formatPrecisionNumber(rowData.data?.[colBodyOptions.field], 2)}%</div>
        {Boolean(!inputIsValid) && (
          <UncontrolledTooltip
            placement="top"
            trigger="hover"
            target={slugToId(`${rowData.data.description}-${colBodyOptions.field}`)}
          >
            Value must be between -5 and 5
          </UncontrolledTooltip>
        )}
      </div>
    );
  };

  const investmentNumberEditor = (rowData, colBodyOptions) => (
    <div>
      <InputNumber
        value={rowData[colBodyOptions.field]}
        className="table-editor noPrint"
        id={slugToId(`${colBodyOptions.field}`)}
        onChange={(event) => {
          const onlyNumbers = /\d+/g;
          if (onlyNumbers.test(event.value.toString())) {
            props.dispatchUpdateStudy({ [colBodyOptions.field]: event.value });
          }
        }}
        allowEmpty={false}
        inputMode="number"
        mode="decimal"
        locale="en-US"
        minFractionDigits={decimalPrecision}
        maxFractionDigits={decimalPrecision}
        onFocus={(event) => {
          event.target.selectionStart = 0;
          event.target.selectionEnd = event.target.value.length;
        }}
      />
      <div className="printOnly">{rowData[colBodyOptions.field] || 0}</div>
    </div>
  );

  // const getDescriptionBody = (rowData) => (
  //   <div className="description">
  //     <div id={`${rowData.description.toLowerCase()}-description`}>{rowData.title}</div>
  //   </div>
  // );

  const toggleShowShock = () => {
    setIsShowingShock(!isShowingShock);
  };

  const addChild = () => {
    if (selectedNodeKeyState) {
      const selectedCategogyId = Number(selectedNodeKeyState[0]);
      const newCashFlows = [
        ...cashFlows,
        {
          fixed: false,
          categoryId: selectedCategogyId,
          description: 'NewNode',
          year0: 0,
          year1: 0,
          year2: 0,
          year3: 0,
          year4: 0,
          year5: 0,
        },
      ];
      setNewCashFlows(newCashFlows);
    } else {
      NotificationManager.error(
        'Please select a row to create the row in the right place',
        'No row selected',
        Constants.POP_UP_TIME
      );
    }
  };

  const deleteRow = (rowData) => {
    const idx = cashFlows.indexOf(rowData.data);
    const newCashFlows = [...cashFlows.slice(0, idx), ...cashFlows.slice(idx + 1)];
    setNewCashFlows(newCashFlows);
  };

  const cashFlowDescriptionHeader = (
    <div className="cashflow-description-header">
      <div>
        <span>Description</span>
      </div>
      <div>
        <Button id="collapse-all-button" color="link" onClick={() => collapseAllKeys()}>
          <i className="pi nepc-pi-collapse-all"></i>
        </Button>
        <Button id="expand-all-button" color="link" onClick={() => expandAllKeys()}>
          <i className="pi nepc-pi-expand-all"></i>
        </Button>
        <UncontrolledTooltip placement="top" trigger="hover" target="collapse-all-button">
          Collapse all
        </UncontrolledTooltip>
        <UncontrolledTooltip placement="top" trigger="hover" target="expand-all-button">
          Expand all
        </UncontrolledTooltip>
      </div>
    </div>
  );

  const cashFlowDescriptionBody = (rowData) => {
    const isTouchScreen = !('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0);
    return (
      <div className="desc-child child-row">
        {Boolean(!rowData.data.fixed) && (
          <ManagementButton backIcon="pi-trash" action={() => deleteRow(rowData)} circle showOnHover={isTouchScreen} />
        )}
        <InputText
          value={rowData.data.description}
          className="table-editor"
          onSelect={() => setSelectedNodeKeyState(rowData.key)}
          onChange={({ target: { value } }) => handleTextEditorValueChange(rowData, value)}
          disabled={rowData.data.fixed}
        ></InputText>
      </div>
    );
  };

  const shockTestDescriptionBody = (rowData) => (
    <div className="description desc-child child-row">
      <InputText
        value={rowData.data.description}
        className="table-editor"
        onChange={({ target: { value } }) => handleShockTextEditorValueChange(rowData, value)}
        disabled={rowData.data.fixed}
      ></InputText>
      {Boolean(Constants.ECONOMIC_SCENARIOS_NAMES.includes(rowData.data.description?.toLowerCase())) && (
        <>
          <i className="pi pi-info-circle" style={{ color: 'red', 'align-content': 'space-around' }}></i>
          <UncontrolledTooltip placement="top" trigger="hover" target={`.pi-info-circle`}>
            Shock test name cannot be equal to economic scenario categories
          </UncontrolledTooltip>
        </>
      )}
      <FormGroup check inline id="manual-input-cf" className="noPrint">
        <Input
          id={`manual-input-checkbox-shocktests-${rowData.data.source}`}
          type="checkbox"
          checked={rowData.data.isManualInput}
          onChange={({ target: { checked } }) => handleShockTextEditorUseValueChange(rowData, checked)}
        />
        <Label for={`manual-input-checkbox-shocktests-${rowData.data.source}`} check>
          Use
        </Label>
      </FormGroup>
    </div>
  );

  const footerTd = (partialSum, decimalprecision, targetClassName) => {
    return (
      <>
        {formatPrecisionNumber(partialSum, decimalprecision)}
        <UncontrolledTooltip placement="top" trigger="hover" target={`.${targetClassName}`}>
          Read Only
        </UncontrolledTooltip>
      </>
    );
  };

  const firstFooterGroup = (
    <ColumnGroup className="noPrint">
      <Row className="cashFooter">
        <Column footer="Subtotal:" className="first-column noPrint" colSpan={2} />
        <Column footer="Subtotal:" className="first-column printOnly" />
        <Column
          footer={() => footerTd(partialSum.year0, decimalPrecision, 'subtotal-year0')}
          className="subtotal-year0"
        />
        <Column
          footer={() => footerTd(partialSum.year1, decimalPrecision, 'subtotal-year1')}
          className="subtotal-year1"
        />
        <Column
          footer={() => footerTd(partialSum.year2, decimalPrecision, 'subtotal-year2')}
          className="subtotal-year2"
        />
        <Column
          footer={() => footerTd(partialSum.year3, decimalPrecision, 'subtotal-year3')}
          className="subtotal-year3"
        />
        <Column
          footer={() => footerTd(partialSum.year4, decimalPrecision, 'subtotal-year4')}
          className="subtotal-year4"
        />
        <Column
          footer={() => footerTd(partialSum.year5, decimalPrecision, 'subtotal-year5')}
          className="subtotal-year5"
        />
      </Row>
    </ColumnGroup>
  );

  const secondFooterGroup = (
    <ColumnGroup>
      <Row className="cashFooter">
        <Column footer="Net Cash Flow:" className="first-column" />
        <Column
          footer={() => footerTd(totalSum.year0, decimalPrecision, 'Net-Cash-Flow-year0')}
          className="Net-Cash-Flow-year0"
        />
        <Column
          footer={() => footerTd(totalSum.year1, decimalPrecision, 'Net-Cash-Flow-year1')}
          className="Net-Cash-Flow-year1"
        />
        <Column
          footer={() => footerTd(totalSum.year2, decimalPrecision, 'Net-Cash-Flow-year2')}
          className="Net-Cash-Flow-year2"
        />
        <Column
          footer={() => footerTd(totalSum.year3, decimalPrecision, 'Net-Cash-Flow-year3')}
          className="Net-Cash-Flow-year3"
        />
        <Column
          footer={() => footerTd(totalSum.year4, decimalPrecision, 'Net-Cash-Flow-year4')}
          className="Net-Cash-Flow-year4"
        />
        <Column
          footer={() => footerTd(totalSum.year5, decimalPrecision, 'Net-Cash-Flow-year5')}
          className="Net-Cash-Flow-year5"
        />
      </Row>
    </ColumnGroup>
  );

  const handleRowReorder = ({ value }) => {
    const reorderedItems = value.map((item) => item.data);
    const reorderedCategoryId = reorderedItems[0].categoryId;
    const newCashFlows = [
      ...cashFlows.filter((cashFlow) => cashFlow.categoryId !== reorderedCategoryId),
      ...reorderedItems,
    ];
    setNewCashFlows(newCashFlows);
  };

  const rowClassName = (node) => {
    const especificRowClassName = node.data.fixed ? `fixed ${node.data.description.replaceAll(' ', '')}` : '';
    return { [especificRowClassName]: true };
  };

  const selectedRows = selectedNodeKeyState ? findNodeByKey(nodesState, selectedNodeKeyState) : null;
  const renderRowExpansionTemplate = ({ children, description }) => {
    return (
      <div className="orders-subtable">
        <DataTable
          id={slugToId(`${description}-table`)}
          onRowReorder={handleRowReorder}
          reorderableRows
          value={children}
          selectionMode="single"
          responsiveLayout="scroll"
          selection={selectedRows}
          rowClassName={rowClassName}
          onSelectionChange={(event) => setSelectedNodeKeyState(event.value.key)}
        >
          <Column rowReorder className="noPrint" style={{ width: '3%' }}></Column>
          <Column
            columnKey={({ key }) => `description-cash-flows-${key.join('-')}`}
            id={({ key }) => `description-cash-flows-${key.join('-')}`}
            body={(rowData) => cashFlowDescriptionBody(rowData)}
            headerClassName="description-header"
            className="cash-flows-subdescription"
          ></Column>
          <Column
            field="year0"
            className="year0-cash-flows"
            columnKey={({ key }) => `year0-cash-flows-${key.join('-')}`}
            id={({ key }) => `year0-cash-flows-${key.join('-')}`}
            body={(rowData, colBodyOptions) => numberEditor(rowData, colBodyOptions)}
          ></Column>
          <Column
            field="year1"
            columnKey={({ key }) => `year1-cash-flows-${key.join('-')}`}
            id={({ key }) => `year1-cash-flows-${key.join('-')}`}
            className="year1-cash-flows"
            body={(rowData, colBodyOptions) => numberEditor(rowData, colBodyOptions)}
          ></Column>
          <Column
            field="year2"
            columnKey={({ key }) => `year2-cash-flows-${key.join('-')}`}
            id={({ key }) => `year2-cash-flows-${key.join('-')}`}
            className="year2-cash-flows"
            body={(rowData, colBodyOptions) => numberEditor(rowData, colBodyOptions)}
          ></Column>
          <Column
            field="year3"
            columnKey={({ key }) => `year3-cash-flows-${key.join('-')}`}
            id={({ key }) => `year3-cash-flows-${key.join('-')}`}
            className="year3-cash-flows"
            body={(rowData, colBodyOptions) => numberEditor(rowData, colBodyOptions)}
          ></Column>
          <Column
            field="year4"
            columnKey={({ key }) => `year4-cash-flows-${key.join('-')}`}
            id={({ key }) => `year4-cash-flows-${key.join('-')}`}
            className="year4-cash-flows"
            body={(rowData, colBodyOptions) => numberEditor(rowData, colBodyOptions)}
          ></Column>
          <Column
            field="year5"
            columnKey={({ key }) => `year5-cash-flows-${key.join('-')}`}
            id={({ key }) => `year5-cash-flows-${key.join('-')}`}
            className="year5-cash-flows"
            body={(rowData, colBodyOptions) => numberEditor(rowData, colBodyOptions)}
          ></Column>
        </DataTable>
      </div>
    );
  };

  const renderShockTestRowExpansionTemplate = ({ children, description }) => (
    <div className="orders-shock-test-subtable">
      <DataTable
        id={slugToId(`${description}-table`)}
        value={children}
        selectionMode="single"
        responsiveLayout="scroll"
        rowClassName={rowClassName}
        onSelectionChange={(event) => setSelectedNodeKeyState(event.value.key)}
      >
        <Column className="noPrint" style={{ width: '3%' }}></Column>
        <Column
          columnKey={({ key }) => `description-shock-test-${key.join('-')}`}
          id={({ key }) => `description-shock-test-${key.join('-')}`}
          body={(rowData) => shockTestDescriptionBody(rowData)}
          bodyClassName="description-body"
          headerClassName="description-header"
          className="shock-test-subdescription"
        ></Column>
        <Column
          field="year0"
          className="year0-shock-test"
          columnKey={({ key }) => `year0-shock-test-${key.join('-')}`}
          id={({ key }) => `year0-shock-test-${key.join('-')}`}
        ></Column>
        <Column
          field="year1"
          columnKey={({ key }) => `year1-shock-test-${key.join('-')}`}
          id={({ key }) => `year1-shock-test-${key.join('-')}`}
          className="year1-shock-test"
          body={(rowData, colBodyOptions) => numberShockEditor(rowData, colBodyOptions)}
        ></Column>
        <Column
          field="year2"
          columnKey={({ key }) => `year2-shock-test-${key.join('-')}`}
          id={({ key }) => `year2-shock-test-${key.join('-')}`}
          className="year2-shock-test"
          body={(rowData, colBodyOptions) => numberShockEditor(rowData, colBodyOptions)}
        ></Column>
        <Column
          field="year3"
          columnKey={({ key }) => `year3-shock-test-${key.join('-')}`}
          id={({ key }) => `year3-shock-test-${key.join('-')}`}
          className="year3-shock-test"
          body={(rowData, colBodyOptions) => numberShockEditor(rowData, colBodyOptions)}
        ></Column>
        <Column
          field="year4"
          columnKey={({ key }) => `year4-shock-test-${key.join('-')}`}
          id={({ key }) => `year4-shock-test-${key.join('-')}`}
          className="year4-shock-test"
          body={(rowData, colBodyOptions) => numberShockEditor(rowData, colBodyOptions)}
        ></Column>
        <Column
          field="year5"
          columnKey={({ key }) => `year5-shock-test-${key.join('-')}`}
          id={({ key }) => `year5-shock-test-${key.join('-')}`}
          className="year5-shock-test"
          body={(rowData, colBodyOptions) => numberShockEditor(rowData, colBodyOptions)}
        ></Column>
      </DataTable>
    </div>
  );

  const expandedRows = expandedKeys ? expandedKeys.map((row) => nodesState.find((node) => node.key === row)) : [];
  const shockExpandedRows = shockExpandedKeys
    ? shockExpandedKeys.map((row) => shockNodesState.find((node) => node.key === row))
    : [];
  return (
    <DefaultBox>
      <StyledCashFlows>
        <div className="cash-flows-header">
          <h2>Cash Flow Projections (${measureScale.measureSymbol})</h2>
          <div className="cash-flows-header-buttons">
            <ManagementButton
              action={() => toggleShowShock()}
              btnText={(isShowingShock ? 'Hide' : 'Show') + ' Shock'}
              id="show-shock-cashflows-button"
              border
            />
            <ManagementButton action={() => addChild()} btnText="Add Row" id="add-row-cashflows-button" border />
          </div>
        </div>
        {Boolean(nodesState) && (
          <div className="cash-flows-table">
            <DataTable
              value={nodesState}
              expandedRows={expandedRows}
              onRowToggle={(event) => setExpandedKeys(event.data.map((row) => row.key || []))}
              rowExpansionTemplate={renderRowExpansionTemplate}
              footerColumnGroup={firstFooterGroup}
              selectionMode="single"
              responsiveLayout="scroll"
              selection={selectedRows}
              onSelectionChange={(event) => setSelectedNodeKeyState(event.value.key)}
            >
              <Column
                expander
                className="noPrint"
                bodyClassName={({ key }) => `arrow-${key}`}
                style={{ width: '3%', padding: '0 5px 0 0' }}
              />
              <Column
                field="description"
                header={cashFlowDescriptionHeader}
                columnKey={({ key }) => `description-cash-flows-${key.join('-')}`}
                id={({ key }) => `description-cash-flows-${key.join('-')}`}
                className="cash-flows-description"
                body={(rowData) => (
                  <div className="parent-row" id={rowData.description}>
                    {rowData.description}
                  </div>
                )}
              ></Column>
              <Column
                field="year0"
                header={selectedStudy.study.columnHead0Year}
                columnKey={({ key }) => `year0-cash-flows-${key.join('-')}`}
                id={({ key }) => `year0-cash-flows-${key.join('-')}`}
                className={'year0-cash-flows'}
                body={(rowData, colBodyOptions) => numberEditor(rowData, colBodyOptions)}
              ></Column>
              <Column
                field="year1"
                header={selectedStudy.study.columnHead1Year}
                columnKey={({ key }) => `year1-cash-flows-${key.join('-')}`}
                id={({ key }) => `year1-cash-flows-${key.join('-')}`}
                className="year1-cash-flows"
                body={(rowData, colBodyOptions) => numberEditor(rowData, colBodyOptions)}
              ></Column>
              <Column
                field="year2"
                header={selectedStudy.study.columnHead2Year}
                columnKey={({ key }) => `year2-cash-flows-${key.join('-')}`}
                id={({ key }) => `year2-cash-flows-${key.join('-')}`}
                className="year2-cash-flows"
                body={(rowData, colBodyOptions) => numberEditor(rowData, colBodyOptions)}
              ></Column>
              <Column
                field="year3"
                header={selectedStudy.study.columnHead3Year}
                columnKey={({ key }) => `year3-cash-flows-${key.join('-')}`}
                id={({ key }) => `year3-cash-flows-${key.join('-')}`}
                className="year3-cash-flows"
                body={(rowData, colBodyOptions) => numberEditor(rowData, colBodyOptions)}
              ></Column>
              <Column
                field="year4"
                header={selectedStudy.study.columnHead4Year}
                columnKey={({ key }) => `year4-cash-flows-${key.join('-')}`}
                id={({ key }) => `year4-cash-flows-${key.join('-')}`}
                className="year4-cash-flows"
                body={(rowData, colBodyOptions) => numberEditor(rowData, colBodyOptions)}
              ></Column>
              <Column
                field="year5"
                header={selectedStudy.study.columnHead5Year}
                columnKey={({ key }) => `year5-cash-flows-${key.join('-')}`}
                id={({ key }) => `year5-cash-flows-${key.join('-')}`}
                className="year5-cash-flows"
                body={(rowData, colBodyOptions) => numberEditor(rowData, colBodyOptions)}
              ></Column>
            </DataTable>
            <DataTable
              value={[selectedStudy.study]}
              footerColumnGroup={secondFooterGroup}
              className="budget-table"
              responsiveLayout="scroll"
            >
              <Column
                field="description"
                id="description"
                body="Budgeted Investment Gain/Loss"
                className="cash-flows-description"
                style={{ width: '35%' }}
              ></Column>
              <Column
                field="budgetedInvestmentGainLossYear0"
                id="budgeted-investment-gainloss-year-0"
                className="year0-cash-flows"
                body={(rowData, colBodyOptions) => investmentNumberEditor(rowData, colBodyOptions)}
                style={{ width: '10.8%' }}
              ></Column>
              <Column
                field="budgetedInvestmentGainLossYear1"
                id="budgeted-investment-gainloss-year-1"
                body={(rowData, colBodyOptions) => investmentNumberEditor(rowData, colBodyOptions)}
                style={{ width: '10.8%' }}
              ></Column>
              <Column
                field="budgetedInvestmentGainLossYear2"
                id="budgeted-investment-gainloss-year-2"
                body={(rowData, colBodyOptions) => investmentNumberEditor(rowData, colBodyOptions)}
                style={{ width: '10.8%' }}
              ></Column>
              <Column
                field="budgetedInvestmentGainLossYear3"
                id="budgeted-investment-gainloss-year-3"
                body={(rowData, colBodyOptions) => investmentNumberEditor(rowData, colBodyOptions)}
                style={{ width: '10.8%' }}
              ></Column>
              <Column
                field="budgetedInvestmentGainLossYear4"
                id="budgeted-investment-gainloss-year-4"
                body={(rowData, colBodyOptions) => investmentNumberEditor(rowData, colBodyOptions)}
                style={{ width: '10.8%' }}
              ></Column>
              <Column
                field="budgetedInvestmentGainLossYear5"
                id="budgeted-investment-gainloss-year-5"
                body={(rowData, colBodyOptions) => investmentNumberEditor(rowData, colBodyOptions)}
                style={{ width: '10.8%' }}
              ></Column>
            </DataTable>
            {isShowingShock && (
              <div className="shock-test-table">
                <DataTable
                  value={shockNodesState}
                  expandedRows={shockExpandedRows}
                  onRowToggle={(event) => setShockExpandedKeys(event.data.map((row) => row.key || []))}
                  rowExpansionTemplate={renderShockTestRowExpansionTemplate}
                  responsiveLayout="scroll"
                >
                  <Column
                    expander
                    className="noPrint"
                    bodyClassName={({ key }) => `arrow-${key}`}
                    style={{ width: '3%', padding: '0 5px 0 0' }}
                  />
                  <Column
                    field="description"
                    columnKey={({ key }) => `description-shock-test-${key.join('-')}`}
                    id={({ key }) => `description-shock-test-${key.join('-')}`}
                    className="shock-test-description"
                  ></Column>
                  <Column
                    field="year0"
                    columnKey={({ key }) => `year0-shock-test-${key.join('-')}`}
                    id={({ key }) => `year0-shock-test-${key.join('-')}`}
                    className={'year0-shock-test'}
                  ></Column>
                  <Column
                    field="year1"
                    columnKey={({ key }) => `year1-shock-test-${key.join('-')}`}
                    id={({ key }) => `year1-shock-test-${key.join('-')}`}
                    className="year1-shock-test"
                  ></Column>
                  <Column
                    field="year2"
                    columnKey={({ key }) => `year2-shock-test-${key.join('-')}`}
                    id={({ key }) => `year2-shock-test-${key.join('-')}`}
                    className="year2-shock-test"
                  ></Column>
                  <Column
                    field="year3"
                    columnKey={({ key }) => `year3-shock-test-${key.join('-')}`}
                    id={({ key }) => `year3-shock-test-${key.join('-')}`}
                    className="year3-shock-test"
                  ></Column>
                  <Column
                    field="year4"
                    columnKey={({ key }) => `year4-shock-test-${key.join('-')}`}
                    id={({ key }) => `year4-shock-test-${key.join('-')}`}
                    className="year4-shock-test"
                  ></Column>
                  <Column
                    field="year5"
                    columnKey={({ key }) => `year5-shock-test-${key.join('-')}`}
                    id={({ key }) => `year5-shock-test-${key.join('-')}`}
                    className="year5-shock-test"
                  ></Column>
                </DataTable>
              </div>
            )}
          </div>
        )}
      </StyledCashFlows>
    </DefaultBox>
  );
};

const calcShockTest = (selectedStudy) => {
  return [
    {
      ...selectedStudy.shockTests?.shockTestA,
      categoryId: 1,
      index: 1,
      source: 'shockTestA',
    },
    {
      ...selectedStudy.shockTests?.shockTestB,
      categoryId: 1,
      index: 2,
      source: 'shockTestB',
    },
  ];
};

CashFlows.propTypes = {
  calculatedCashFlows: PropTypes.object,
  calculatedChockTests: PropTypes.object,
  dispatchCalculatedCashFlows: PropTypes.func,
  dispatchCashFlowsData: PropTypes.func,
  dispatchShockTest: PropTypes.func,
  dispatchToExportStudy: PropTypes.func,
  dispatchUpdateStudy: PropTypes.func,
  measureScale: PropTypes.object,
  selectedStudy: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    calculatedCashFlows: calcCashFlows(state.ui),
    measureScale: state.ui.measureScale,
    printing: state.ui.printing || false,
    selectedStudy: state.ui.selectedStudy || {},
    calculatedChockTests: calcShockTest(state.ui.selectedStudy),
  };
};

export default connect(mapStateToProps, {
  dispatchCalculatedCashFlows,
  dispatchCashFlowsData,
  dispatchShockTest,
  dispatchToExportStudy,
  dispatchUpdateStudy,
})(CashFlows);
