import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { StyledSecurityPanel } from './StyledCards';
import { saveUsersAccess, loadStudyUsersAccess, loadStudyUsers } from '../../actions';
import ManagementButton from '../ManagementButton';

export const LevelSecurity = (props) => {
  const [selectedUsers, setSelectedUsers] = useState(props.studyUsers || null);
  const multiSortMeta = [
    { field: 'owner', order: 1 },
    { field: 'company', order: 1 },
    { field: 'name', order: 1 },
  ];

  useEffect(() => {
    return _.debounce(() => {
      props.loadStudyUsers(props.clientGuid);
      props.loadStudyUsersAccess(props.studyId);
    });
  }, [props.saveUsersAccess]);

  useEffect(() => {
    const newStudyUsersData = props.studyUsers.length > 0 ? props.studyUsers : selectedUsers;
    return _.debounce(() => {
      const usrs = props.studyUsersAccess.filter((usr) => {
        if (
          usr.email === props.owner ||
          newStudyUsersData.find((a) => a.email === usr.email) ||
          (!props.studyUsersRequest.loading && props.studyUsers === '')
        ) {
          return true;
        }
        return false;
      });
      return setSelectedUsers(usrs);
    });
  }, [props.studyUsers, props.studyUsersAccess, props.studyUsersRequest, props.studyId]);

  const concatValues = props.studyUsersAccess.map((a) => {
    if (a.email === props.owner) {
      return { ...a, name: `${a.name} (owner)`, owner: true };
    } else return a;
  });

  const ownerIsInTheTable = () => {
    return Boolean(props.owner && concatValues?.find((a) => a.email === props.owner));
  };

  const mapToSave = () => {
    const mappedUsersToSave = [];
    selectedUsers.forEach((element) => {
      mappedUsersToSave.push({
        studyId: props.studyId,
        clientGuid: props.clientGuid,
        email: element.email,
      });
    });
    return mappedUsersToSave;
  };

  const rowClassName = (data) => {
    return data.email === props.owner ? 'p-disabled' : '';
  };

  return (
    <StyledSecurityPanel>
      <div className="dialog-header">
        <ManagementButton
          btnText="Save"
          disabled={props.studyUsersRequest.loading || selectedUsers?.length < 1}
          action={() => props.saveUsersAccess(props.studyId, mapToSave())}
        />
        <ManagementButton
          btnText="Save & Close"
          disabled={props.studyUsersRequest.loading || selectedUsers?.length < 1}
          action={() => {
            props.saveUsersAccess(props.studyId, mapToSave());
            props.hideDialog();
          }}
        />
      </div>
      <DataTable
        className="p-datatable-sm"
        value={concatValues}
        selection={
          ownerIsInTheTable()
            ? [{ ...concatValues.find((a) => a.email === props.owner), owner: true }, ...selectedUsers]
            : selectedUsers
        }
        loading={props.studyUsersAccessRequest.loading}
        onSelectionChange={(e) => {
          const usrs = props.studyUsersAccess.filter((usr) => {
            if (e.value.find((a) => a.email === usr.email)) {
              return true;
            } else {
              return false;
            }
          });
          return setSelectedUsers(usrs);
        }}
        rowClassName={rowClassName}
        sortMode="multiple"
        multiSortMeta={multiSortMeta}
      >
        <Column selectionMode="multiple" style={{ width: '3em' }} />
        <Column field="name" header="Name"></Column>
        <Column field="email" header="Email"></Column>
        <Column field="company" header="Company"></Column>
      </DataTable>
    </StyledSecurityPanel>
  );
};

LevelSecurity.propTypes = {
  studyUsersAccess: PropTypes.array,
  studyUsersAccessRequest: PropTypes.object,
  studyId: PropTypes.number,
  clientGuid: PropTypes.string,
  owner: PropTypes.string,
  studyUsers: PropTypes.array,
  studyUsersRequest: PropTypes.object,

  saveUsersAccess: PropTypes.func,
  hideDialog: PropTypes.func,
  loadStudyUsersAccess: PropTypes.func,
  loadStudyUsers: PropTypes.func,
};

const mapStateToProps = (state) => ({
  studyUsersAccess: state.resources.study_users_access.data || [],
  studyUsersAccessRequest: state.resources.study_users_access || {},
  studyUsers: state.resources.study_users_data.data || [],
  studyUsersRequest: state.resources.study_users_data || {},
});

export default connect(mapStateToProps, { saveUsersAccess, loadStudyUsersAccess, loadStudyUsers })(LevelSecurity);
