import moment from 'moment';

import printService from '../services/printService';
import { addEvent } from './EventActions';
import { Events } from '../services/Constants';
import { withLoading } from './CommonActions';
import * as types from './Types';

const MAX_PIXELS_PER_PAGE = 1070;

const printDocument = (sendData, filename, template, html, orientation = 'portrait') => {
  return printService().printHtmlPdf(sendData, filename, template, orientation, html);
};

const haveClientName = (clientName) => {
  return clientName !== null && clientName !== '';
};

export const printStudyData = withLoading(() => {
  return (dispatch, getState) => {
    const {
      ui: {
        selectedStudy: { study },
        selectedClient,
        measureScale,
        okta,
      },
      resources: {
        assumptions: { data },
      },
    } = getState();
    const assumption = data.find((item) => item.versionPk === study.assumptionId);
    const assetPoolsTable = document.querySelector('.asset-pools-table');
    const assetAllocationGraph = document.querySelector('.asset-allocation-graph');
    const assetAllocationTable = document.querySelector('.asset-allocation-table');
    const economicScenariosTable = document.querySelector('.economic-scenarios-table');
    const cashFlowsTable = document.querySelector('.cash-flows-table');
    const financialMetricsTable = document.querySelector('.financial-metrics-table');
    const economicScenariosGraph = document.querySelector('.economic-scenarios-graph-print');
    const otherFinancialProjections = document.querySelector('.other-financial-projections-table');

    const sendData = {
      studyName: study.studyName,
      clientName: haveClientName(study.clientName) ? study.clientName : selectedClient.name,
      date: moment().format('MM/DD/YYYY'),
      userName: okta?.username,
      documentType: 'Skyan Study',
    };

    dispatch(addEvent(Events.PRINT_STUDY_PDF, study.studyId));
    dispatch({ type: types.PRINTING, printing: false });

    let html = '';

    const sectionNumber = ['I', 'II', 'III', 'IV', 'V', 'VI'];
    let currentNumber = 0;
    let pagePixels = 0;
    if (assetPoolsTable) {
      html = `${html}
      <div class="box asset-pools-table">
        <h3 class="boxTittle">${sectionNumber.at(currentNumber)}. Unrestricted Cash & Investments</h3>
        <div class="gridArea">${assetPoolsTable.innerHTML}</div>
      </div>`;
      pagePixels += assetPoolsTable.clientHeight;
      currentNumber++;
    }
    if (assetAllocationGraph) {
      if (pagePixels + assetAllocationGraph.clientHeight + assetAllocationTable.clientHeight > MAX_PIXELS_PER_PAGE) {
        html = `${html} <div class="brake" />`;
        pagePixels = 0;
      }
      html = `${html}
      <div class="box">
        <h3 class="boxTittle">${sectionNumber.at(currentNumber)}. Asset Allocation & Liquidity</h3>
        <div class="graph asset-allocation-graph">${assetAllocationGraph.innerHTML}</div>
        <div class="gridArea table asset-allocation-table">${assetAllocationTable.innerHTML}</div>
      </div>`;
      pagePixels = pagePixels + assetAllocationGraph.clientHeight + assetAllocationTable.clientHeight;
      currentNumber++;
    }
    if (economicScenariosGraph) {
      if (
        pagePixels + economicScenariosGraph.clientHeight + economicScenariosTable.clientHeight >
        MAX_PIXELS_PER_PAGE
      ) {
        html = `${html} <div class="brake" />`;
        pagePixels = 0;
      }
      html = `${html}
      <div class="box economic-scenario-box">
        <h3 class="boxTittle">${sectionNumber.at(currentNumber)}. Overview of Scenarios - Five Year Projections</h3>
        <div class="graph economic-scenarios-graph">${economicScenariosGraph.innerHTML}</div>
        <div class="gridArea economic-scenarios-table">${economicScenariosTable.innerHTML}</div>
      </div>`;
      pagePixels = pagePixels + economicScenariosGraph.clientHeight + economicScenariosTable.clientHeight;
      currentNumber++;
    }
    if (cashFlowsTable) {
      if (pagePixels + cashFlowsTable.clientHeight > MAX_PIXELS_PER_PAGE) {
        html = `${html} <div class="brake" />`;
        pagePixels = 0;
      }
      html = `${html}
      <div class="box">
        <h3 class="boxTittle">${sectionNumber.at(currentNumber)}. Cash Flow Projections ($${
        measureScale.measureSymbol
      })</h3>
        <div class="gridArea cash-flows-table">
          ${cashFlowsTable.innerHTML}
        </div>
      </div>`;
      pagePixels = pagePixels + cashFlowsTable.clientHeight;
      currentNumber++;
    }
    if (otherFinancialProjections) {
      if (pagePixels + otherFinancialProjections.clientHeight > MAX_PIXELS_PER_PAGE) {
        html = `${html} <div class="brake" />`;
        pagePixels = 0;
      }
      html = `${html}
      <div class="box">
        <h3 class="boxTittle">${sectionNumber.at(currentNumber)}. Other Financial Projections ($${
        measureScale.measureSymbol
      })</h3>
        <div class="gridArea other-financial-table">
          ${otherFinancialProjections.innerHTML}
        </div>
      </div>`;
      pagePixels = pagePixels + otherFinancialProjections.clientHeight;
      currentNumber++;
    }
    if (financialMetricsTable) {
      if (pagePixels + financialMetricsTable.clientHeight > MAX_PIXELS_PER_PAGE) {
        html = `${html} <div class="brake" />`;
        pagePixels = 0;
      }
      html = `${html}
      <div class="box">
        <h3 class="boxTittle">${sectionNumber.at(currentNumber)}. Financial metrics</h3>
        <div class="gridArea financial-metrics-table">
          ${financialMetricsTable.innerHTML}
        </div>
      </div>`;
      pagePixels = pagePixels + financialMetricsTable.clientHeight;
    }
    html = `${html}
      <div class="text-justify disclaimer ${pagePixels + 420 > MAX_PIXELS_PER_PAGE ? 'brake' : ''}">  
        Date created: ${moment().format('MM/DD/YYYY')}
        Created by: ${okta?.username}
        Asset Allocation assumption set selected: ${moment(assumption.asOf).format('MM/DD/YYYY')}
        The Asset Allocation Proxy was selected by NEPC.  The 10-year expected return and standard deviation were based on NEPC’s asset class assumptions.  All other data was provided and entered by the user.
        The Asset Allocation bar charts display potential asset allocation options based on the mix of asset classes suggested by each Asset Allocation Proxy.
      </div>
      <div class="disclaimer disclaimer-footer">
        <span class="fpl-disclaimer-title">Disclaimers:</span>
        <ul style="list-style-type: square;">
          <li>The goal of this tool is to provide a basis for substantiating asset allocation recommendations.</li>
          <li>Information on market indices was provided by sources external to NEPC.  While NEPC has exercised reasonable professional care in preparing this tool, we cannot guarantee the accuracy of all source information contained within.</li>
          <li>The scenarios and projections were determined by data supplied by the user.</li>
          <li>All investments carry some level of risk.  Diversification and other asset allocation techniques are not guaranteed to ensure profit or protect against losses.</li>
          <li>Past performance is no guarantee of future results.</li>
        </ul>
      </div>
`;

    return printDocument(
      sendData,
      `${study.studyName.replaceAll(' ', '')}.pdf`,
      'erm_study',
      html.replaceAll('<td role="cell" colspan="8">', '<td role="cell" class="child-table" colspan="8">')
    );
  };
});
