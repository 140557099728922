import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  Label,
  UncontrolledTooltip,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Checkbox } from 'primereact/checkbox';

import ManagementButton from '../ManagementButton';

import {
  dispatchCheckStudyValidity,
  dispatchDisplaySingleColumnLayout,
  dispatchPrinting,
  toggleSettingsMenu,
  saveStudy,
  loadStudyUsersAccess,
  loadStudyUsers,
  isStudyModified,
} from '../../actions';

import { ActionButtons, LastCalculation, StudyHeader, StudyTittle, ToggleText } from './styles';

const _StudyHeader = ({
  dispatchDisplaySingleColumnLayout,
  displaySingleColumnLayout,
  loadStudyUsersAccess,
  toggleSettingsMenu,
  studyProps,
  saveStudy,
  confirmDeleteStudy,
  confirmRevertStudy,
  confirmQuitStudy,
  exportStudyToExcel,
  handleStudyNameChange,
  handleCloneStudy,
  handlePrint,
  isStudyModified,
  setSecurity,
  selectedStudy,
}) => {
  const [dropMenuOpen, setDropMenuOpen] = useState();
  const [_isStudyModified, setIsStudyModified] = useState(false);

  useEffect(() => {
    setIsStudyModified(isStudyModified());
  }, [studyProps, selectedStudy]);

  return (
    <StudyHeader>
      <StudyTittle>
        <ManagementButton backIcon="pi pi-angle-left" action={confirmQuitStudy} circle />
        <ButtonDropdown isOpen={dropMenuOpen} toggle={() => setDropMenuOpen(!dropMenuOpen)}>
          <DropdownToggle color="" className="mainToggle" id="main-study-toggle">
            <ToggleText id="study-title-dropdown">
              <h3 data-pr-tooltip={studyProps.study.studyName}>{studyProps.study.studyName}</h3>
              <i className="pi pi-bars"></i>
            </ToggleText>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>
              <Label>Study name:</Label>
            </DropdownItem>
            <DropdownItem header>
              <InputGroup size="sm" className="StudyNameInput">
                <Input
                  type="name"
                  name="name"
                  id="study-name-input"
                  value={studyProps.study.studyName}
                  onChange={(event) => handleStudyNameChange(event)}
                />
              </InputGroup>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={() => saveStudy()} disabled={!_isStudyModified} id="save-study-in-dropdown">
              <i className="pi pi-save"></i>
              Save Study...
            </DropdownItem>
            <DropdownItem onClick={() => confirmRevertStudy()} disabled={!_isStudyModified} id="revert-study">
              <i className="pi pi-replay"></i>
              Revert Study...
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={() => handleCloneStudy()} disabled={_isStudyModified} id="clone-study">
              <i className="pi pi-clone"></i>
              Clone Study...
            </DropdownItem>
            <DropdownItem onClick={() => confirmDeleteStudy()} id="delete-study">
              <i className="pi pi-trash"></i>
              Delete Study...
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={() => exportStudyToExcel()} id="export">
              <i className="pi pi-file-excel"></i>
              Export to Excel
            </DropdownItem>
            <DropdownItem onClick={() => toggleSettingsMenu()} id="open-settings-in-dropdown">
              <i className="pi pi-cog"></i>
              Study settings...
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                loadStudyUsersAccess(studyProps.study.studyId);
                setSecurity(true);
              }}
              id="open-security-settings"
            >
              <i className="pi pi-shield"></i>
              Security
            </DropdownItem>
            {Boolean(_isStudyModified) && (
              <UncontrolledTooltip placement="right" trigger="hover" target="clone-study">
                Save or Revert before cloning
              </UncontrolledTooltip>
            )}
          </DropdownMenu>
        </ButtonDropdown>
      </StudyTittle>
      <ActionButtons>
        {studyProps.study.lastCalculation ? (
          <LastCalculation>
            Last calculation: {new Date(studyProps.study.lastCalculation).toLocaleString('en-US')}
          </LastCalculation>
        ) : (
          ''
        )}
        <div className="p-field-checkbox">
          <Checkbox
            inputId="binary-column-view"
            checked={displaySingleColumnLayout}
            onChange={(event) => dispatchDisplaySingleColumnLayout(event.checked)}
          />
          <label htmlFor="binary-column-view">Single column layout</label>
        </div>
        <ManagementButton
          backIcon="pi-save"
          btnText="Save"
          id="save-button"
          action={() => {
            saveStudy();
            setIsStudyModified(isStudyModified());
          }}
          disabled={!_isStudyModified}
          border
        />
        <ManagementButton
          backIcon="pi-file-pdf"
          btnText="Print PDF"
          action={handlePrint}
          id="print-pdf-button"
          border
        />
        <ManagementButton backIcon="pi-cog" action={toggleSettingsMenu} id="open-settings-button" border />
      </ActionButtons>
    </StudyHeader>
  );
};

_StudyHeader.propTypes = {
  dispatchCheckStudyValidity: PropTypes.func,
  dispatchDisplaySingleColumnLayout: PropTypes.func,
  confirmRevertStudy: PropTypes.func,
  exportStudyToExcel: PropTypes.func,
  confirmDeleteStudy: PropTypes.func,
  handleStudyNameChange: PropTypes.func,
  displaySingleColumnLayout: PropTypes.bool,
  dispatchPrinting: PropTypes.func,
  toggleSettingsMenu: PropTypes.func,
  studyProps: PropTypes.object,
  selectedStudy: PropTypes.object,
  saveStudy: PropTypes.func,
  loadStudyUsersAccess: PropTypes.func,
  loadStudyUsers: PropTypes.func,
  handlePrint: PropTypes.func,
  confirmQuitStudy: PropTypes.func,
  isStudyModified: PropTypes.func,
  handleCloneStudy: PropTypes.func,
  setSecurity: PropTypes.func,
};

const mapStateToProps = (state) => ({
  displaySingleColumnLayout: state.ui.displaySingleColumnLayout,
  selectedClient: state.ui.selectedClient || {},
  studyProps: state.ui.selectedStudy || {},
  studyResources: state.resources.currentstudyresources.data || {},
  selectedStudy: state.resources.selectedStudy || {},
});

export default connect(mapStateToProps, {
  dispatchCheckStudyValidity,
  dispatchDisplaySingleColumnLayout,
  dispatchPrinting,
  toggleSettingsMenu,
  saveStudy,
  loadStudyUsersAccess,
  loadStudyUsers,
  isStudyModified,
})(_StudyHeader);
