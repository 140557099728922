import React, { useEffect, useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import Chart from 'react-google-charts';

import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { TreeTable } from 'primereact/treetable';

import { Button, UncontrolledTooltip } from 'reactstrap';

import {
  calcEconomicScenarios,
  DCOH_CHILD_IDX,
  FORECASTED_AUM_CHILD_IDX,
  INV_GAIN_LOSS_CHILD_IDX,
  INV_RETURN_CHILD_IDX,
} from './CalcEconomicScenarios';
import DefaultBox from '../DefaultBox';
import ManagementButton from '../ManagementButton';
import StyledEconomicScenarios from './StyledEconomicScenarios';

import {
  calcRiskReturn,
  dispatchCheckStudyValidity,
  dispatchIsRelativeToBudget,
  dispatchToExportStudy,
  storeAUMAndReturn,
  storeEconomicScenarios,
} from '../../actions';

import Constants from '../../services/Constants';

const EconomicScenarios = (props) => {
  const {
    calculatedEconomicScenarios,
    economicScenariosWithShock,
    isRelativeToBudget,
    measureScale,
    printing,
    selectedStudy,
    shockTests,
    displaySingleColumnLayout,
  } = props;
  const isEventListenerConnected = useRef(false);
  const [isFullScreen, setFullScreen] = useState(false);

  const { decimalPrecision } = selectedStudy.study;

  const addMeasureScale = (description, childDescription, measure, measureScaleSymbol) =>
    measure === '$'
      ? `${description} (${childDescription ? childDescription + ' - ' : ''}${measure}${measureScaleSymbol})`
      : `${description} (${childDescription ? childDescription + ' - ' : ''}${measure})`;
  const removeMeasureScale = (label) =>
    label
      .replace(/\(.*[$%][MB]?\)/, '')
      .replace(/\(.*\$000\)/, '')
      .replace(/\(.*Days\)/, '');

  const parseNodes = (_selectedCalcValue) =>
    calculatedEconomicScenarios.map((item) => {
      const currentChild =
        item.children.find((child) => child.key == _selectedCalcValue) ||
        item.children.find((child) => child.description.includes('AUM')) ||
        item.children[0];
      return {
        ...item,
        description: addMeasureScale(
          item.description,
          currentChild.description,
          currentChild.measure,
          measureScale.measureSymbol
        ),
        measure: currentChild.measure,
        year0: currentChild.year0,
        year1: currentChild.year1,
        year2: currentChild.year2,
        year3: currentChild.year3,
        year4: currentChild.year4,
        year5: currentChild.year5,
        children: item.children.map((child) => ({
          ...child,
          description: addMeasureScale(child.description, '', child.measure, measureScale.measureSymbol),
        })),
      };
    });

  const _nodes = parseNodes();
  const scenarioOptions = _nodes.map((node) => ({
    name: removeMeasureScale(node.description),
    code: node.key,
  }));

  // TODO Store and load selectedCalcValue from the API (previous selection)
  const calcValuesOptions = _nodes[0]
    ? _nodes[0].children.map((child) => ({
        name: child.description,
        code: child.key,
      }))
    : [];

  const [nodes, setNodes] = useState(_nodes);
  const [selectedCalcValue, setSelectedCalcValue] = useState(
    calcValuesOptions.find((child) => child.name.includes('AUM'))?.code
  );
  const [selectedNodeKey, setSelectedNodeKey] = useState();
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [selectedScenarios, setSelectedScenarios] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState({});
  const [isPrinting, setIsPrinting] = useState(printing);
  const [hasTargetLine1, setHasTargetLine1] = useState(false);
  const [hasTargetLine2, setHasTargetLine2] = useState(false);

  useEffect(() => {
    setSelectedNodes(nodes.filter((node) => selectedScenarios.find((ss) => ss.code == node.key)));
  }, [nodes, selectedScenarios]);

  useEffect(() => {
    let _selectedScenarios = scenarioOptions.filter((scenario) =>
      selectedStudy.study.selectedScenarios?.includes(Number(scenario.code))
    );
    if (scenarioOptions.length && (!_selectedScenarios || !_selectedScenarios.length)) {
      _selectedScenarios = [
        scenarioOptions.find((scenario) => scenario.name.includes('Base Case')),
        scenarioOptions.find((scenario) => scenario.name.includes('Depression')),
        scenarioOptions.find((scenario) => scenario.name.includes('Expansion')),
      ];
    }
    setSelectedNodes(nodes.filter((node) => _selectedScenarios.find((ss) => ss.code == node.key)));
    setSelectedScenarios(_selectedScenarios);
    if (isNaN(selectedCalcValue) && calcValuesOptions.length) {
      setSelectedCalcValue(calcValuesOptions.find((child) => child.name.includes('AUM')).code);
    }
  }, [selectedStudy.study]);

  useEffect(() => {
    const baseCase = nodes.find((node) => node.description.includes('Base'));
    if (baseCase) {
      const forecastedAUM = baseCase.children.find((child) => child.description.includes('AUM'));
      const invReturn = baseCase.children.find((child) => child.description.includes('Return'));
      if (!isNaN(forecastedAUM.year0)) {
        props.storeAUMAndReturn({
          esBaseCaseAumYear0: forecastedAUM.year0,
          esBaseCaseAumYear1: forecastedAUM.year1,
          esBaseCaseAumYear2: forecastedAUM.year2,
          esBaseCaseAumYear3: forecastedAUM.year3,
          esBaseCaseAumYear4: forecastedAUM.year4,
          esBaseCaseAumYear5: forecastedAUM.year5,
          esBaseCaseProjectedReturnYear0: invReturn.year0,
          esBaseCaseProjectedReturnYear1: invReturn.year1,
          esBaseCaseProjectedReturnYear2: invReturn.year2,
          esBaseCaseProjectedReturnYear3: invReturn.year3,
          esBaseCaseProjectedReturnYear4: invReturn.year4,
          esBaseCaseProjectedReturnYear5: invReturn.year5,
        });
      }
    }
  }, [nodes]);

  useEffect(() => {
    setNodes(parseNodes(selectedCalcValue));
    props.dispatchToExportStudy({
      economicScenariosWithShock,
    });
  }, [
    isRelativeToBudget,
    measureScale,
    selectedCalcValue,
    selectedStudy.calculatedCashFlows?.partialSum,
    selectedStudy.economicScenarios,
    selectedStudy.shockTest,
    selectedStudy.financialMetrics.dailyExpenseFactor,
  ]);

  useEffect(() => {
    setIsPrinting(printing);
  }, [printing]);

  useEffect(() => {
    if (!isEventListenerConnected.current) {
      const contentElement = document.getElementById('economic-scenarios');
      if (contentElement) {
        const eventName = getFullScreenChangeEvent(contentElement);
        if (eventName) {
          contentElement.addEventListener(eventName, () => fullScreenChangeListener(setFullScreen));
        }

        isEventListenerConnected.current = true;
      }
    }
  }, [isEventListenerConnected, setFullScreen]);

  const toggleFullScreen = useCallback(() => {
    if (isFullScreen) {
      const requestMethod = getFullScreenCancelMethod();
      if (requestMethod) {
        requestMethod.call(document);
      }
    } else {
      const contentElement = document.getElementById('economic-scenarios');
      const requestMethod = getFullScreenRequestMethod(contentElement);
      if (requestMethod) {
        requestMethod.call(contentElement);
      }
    }
  }, [isFullScreen]);

  const getFullScreenElement = () => {
    if (document.fullscreenEnabled) {
      return document.fullscreenElement;
    } else if (document.webkitFullscreenEnabled) {
      return document.webkitFullscreenElement;
    } else if (document.mozFullScreenEnabled) {
      return document.mozFullScreenElement;
    } else if (document.msFullscreenEnabled) {
      return document.msFullscreenElement;
    } else {
      return;
    }
  };

  const hasEvent = (contentElement, eventName) => {
    for (const key in contentElement) {
      if (eventName === key) {
        return true;
      }
    }
    return false;
  };

  const getFullScreenChangeEvent = (contentElement) => {
    if (document.fullscreenEnabled && hasEvent(contentElement, 'onfullscreenchange')) {
      return 'fullscreenchange';
    } else if (document.webkitFullscreenEnabled && hasEvent(contentElement, 'onwebkitfullscreenchange')) {
      return 'webkitfullscreenchange';
    } else if (document.mozFullScreenEnabled && hasEvent(contentElement, 'onmozfullscreenchange')) {
      return 'mozfullscreenchange';
    } else if (document.msFullscreenEnabled && hasEvent(contentElement, 'onmsfullscreenchange')) {
      return 'msfullscreenchange';
    } else {
      return;
    }
  };

  const getFullScreenCancelMethod = () => {
    if (document.fullscreenEnabled && document.exitFullscreen) {
      return document.exitFullscreen;
    } else if (document.webkitFullscreenEnabled && document.webkitExitFullscreen) {
      return document.webkitExitFullscreen;
    } else if (document.mozFullScreenEnabled && document.mozCancelFullScreen) {
      return document.mozCancelFullScreen;
    } else if (document.msFullscreenEnabled && document.msExitFullscreen) {
      return document.msExitFullscreen;
    } else {
      return;
    }
  };

  const getFullScreenRequestMethod = (contentElement) => {
    if (document.fullscreenEnabled && contentElement.requestFullscreen) {
      return contentElement.requestFullscreen;
    } else if (document.webkitFullscreenEnabled && contentElement.webkitRequestFullscreen) {
      return contentElement.webkitRequestFullscreen;
    } else if (document.mozFullScreenEnabled && contentElement.mozRequestFullScreen) {
      return contentElement.mozRequestFullScreen;
    } else if (document.msFullscreenEnabled && contentElement.msRequestFullscreen) {
      return contentElement.msRequestFullscreen;
    } else {
      return;
    }
  };

  const fullScreenChangeListener = (setFullScreen) => {
    const isFullScreenActive = getFullScreenElement() != null;
    setFullScreen(isFullScreenActive);
  };
  const parseShockTests = () => {
    const data = [...economicScenariosWithShock];
    const newData = data.map((item) => {
      const currentChild =
        item.children.find((child) => child.key == selectedCalcValue) ||
        item.children.find((child) => child.description.includes('AUM')) ||
        item.children[0];
      return {
        ...item,
        measure: currentChild.measure,
        year0: currentChild.year0,
        year1: currentChild.year1,
        year2: currentChild.year2,
        year3: currentChild.year3,
        year4: currentChild.year4,
        year5: currentChild.year5,
        children: item.children.map((child) => ({
          ...child,
          description: addMeasureScale(child.description, '', child.measure, measureScale.measureSymbol),
        })),
      };
    });
    return newData;
  };

  const prettyNumber = (rowData, colBodyOptions) => {
    const value = rowData[colBodyOptions.field];
    if (isNaN(value)) return '';
    if (rowData['measure'] === '%') {
      return (
        <div className={`${rowData.children ? 'parent-row' : 'child-row'}`}>
          {value.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + '%'}
        </div>
      );
    }
    if (rowData['measure'] === 'Days') {
      return (
        <div className={`${rowData.children ? 'parent-row' : 'child-row'}`}>
          {value.toLocaleString('en-US', { maximumFractionDigits: 0, minimumFractionDigits: 0 })}
        </div>
      );
    }
    return (
      <div className={`${rowData.children ? 'parent-row' : 'child-row'}`}>
        {value.toLocaleString('en-US', {
          minimumFractionDigits: decimalPrecision,
          maximumFractionDigits: decimalPrecision,
        })}
      </div>
    );
  };

  const handleCalcRiskReturn = () => {
    const { studyIsValid } = props.dispatchCheckStudyValidity();
    const [isValid, error] = studyIsValid;
    if (error) return NotificationManager.error(error);
    if (isValid) {
      props.calcRiskReturn();
    }
  };

  const collapseAllKeys = () => setExpandedKeys({});

  const expandAllKeys = () => {
    const _expandedKeys = economicScenariosWithShock.reduce((acc, node) => ({ ...acc, [node.key]: true }), {});
    setExpandedKeys(_expandedKeys);
  };

  const scenariosDescriptionHeader = (
    <div className="scenarios-description-header">
      <div>
        <span>Scenario</span>
      </div>
      <div>
        <Button id="collapse-scenario-all-button" color="link" onClick={() => collapseAllKeys()}>
          <i className="pi nepc-pi-collapse-all"></i>
        </Button>
        <Button id="expand-scenario-all-button" color="link" onClick={() => expandAllKeys()}>
          <i className="pi nepc-pi-expand-all"></i>
        </Button>
        <UncontrolledTooltip placement="top" trigger="hover" target="collapse-scenario-all-button">
          Collapse all
        </UncontrolledTooltip>
        <UncontrolledTooltip placement="top" trigger="hover" target="expand-scenario-all-button">
          Expand all
        </UncontrolledTooltip>
      </div>
    </div>
  );

  const economicScenariosDescriptionBody = (rowData, colBodyOptions) => (
    <div className={`${rowData.children ? 'parent-row desc-item' : 'child-row desc-item'}`}>{rowData.description}</div>
  );

  const getTargetLineArray = (label, value) => [label, ...Array(6).fill(value)];

  const getShockTestLineArray = (scenario, key) => {
    if (!scenario) return [];
    const item = scenario.children.find((item) => item.key == key);
    return [scenario.description, item.year0, item.year1, item.year2, item.year3, item.year4, item.year5];
  };

  const sortGraphData = (data) => {
    let itemsCount = 1;
    if (hasTargetLine1) itemsCount++;
    if (hasTargetLine2) itemsCount++;
    const firstLines = data.slice(0, itemsCount);
    data.splice(0, itemsCount);
    data.sort((a, b) => {
      if (a[0] < b[0]) return -1;
      return 1;
    });
    return [...firstLines, ...data];
  };

  const getGraphData = () => {
    const {
      columnHead0Year,
      columnHead1Year,
      columnHead2Year,
      columnHead3Year,
      columnHead4Year,
      columnHead5Year,
      targetDCOH,
      targetDCOH2,
      targetForecastedAum,
      targetForecastedAum2,
      targetInvGainLoss,
      targetInvGainLoss2,
      targetInvReturn,
      targetInvReturn2,
      targetLabel,
      targetLabel2,
    } = selectedStudy.study;
    let graphData = [
      [
        'Scenario',
        columnHead0Year,
        columnHead1Year,
        columnHead2Year,
        columnHead3Year,
        columnHead4Year,
        columnHead5Year,
      ],
    ];

    if (!isRelativeToBudget) {
      switch (selectedCalcValue) {
        case FORECASTED_AUM_CHILD_IDX:
          if (targetForecastedAum) {
            !hasTargetLine1 && setHasTargetLine1(true);
            graphData = [...graphData, getTargetLineArray(targetLabel || 'Target 1', targetForecastedAum)];
          } else {
            hasTargetLine1 && setHasTargetLine1(false);
          }
          if (targetForecastedAum2) {
            !hasTargetLine2 && setHasTargetLine2(true);
            graphData = [...graphData, getTargetLineArray(targetLabel2 || 'Target 2', targetForecastedAum2)];
          } else {
            hasTargetLine2 && setHasTargetLine2(false);
          }
          break;
        case DCOH_CHILD_IDX:
          if (targetDCOH) {
            !hasTargetLine1 && setHasTargetLine1(true);
            graphData = [...graphData, getTargetLineArray(targetLabel || 'Target 1', targetDCOH)];
          } else {
            hasTargetLine1 && setHasTargetLine1(false);
          }
          if (targetDCOH2) {
            !hasTargetLine2 && setHasTargetLine2(true);
            graphData = [...graphData, getTargetLineArray(targetLabel2 || 'Target 2', targetDCOH2)];
          } else {
            hasTargetLine2 && setHasTargetLine2(false);
          }
          break;
        case INV_GAIN_LOSS_CHILD_IDX:
          if (targetInvGainLoss) {
            !hasTargetLine1 && setHasTargetLine1(true);
            graphData = [...graphData, getTargetLineArray(targetLabel || 'Target 1', targetInvGainLoss)];
          } else {
            hasTargetLine1 && setHasTargetLine1(false);
          }
          if (targetInvGainLoss2) {
            !hasTargetLine2 && setHasTargetLine2(true);
            graphData = [...graphData, getTargetLineArray(targetLabel2 || 'Target 2', targetInvGainLoss2)];
          } else {
            hasTargetLine2 && setHasTargetLine2(false);
          }
          break;
        case INV_RETURN_CHILD_IDX:
          if (targetInvReturn) {
            !hasTargetLine1 && setHasTargetLine1(true);
            graphData = [...graphData, getTargetLineArray(targetLabel || 'Target 1', targetInvReturn)];
          } else {
            hasTargetLine1 && setHasTargetLine1(false);
          }
          if (targetInvReturn2) {
            !hasTargetLine2 && setHasTargetLine2(true);
            graphData = [...graphData, getTargetLineArray(targetLabel2 || 'Target 2', targetInvReturn2)];
          } else {
            hasTargetLine2 && setHasTargetLine2(false);
          }
          break;
        default:
          hasTargetLine1 && setHasTargetLine1(false);
          hasTargetLine2 && setHasTargetLine2(false);
      }
    } else {
      hasTargetLine1 && setHasTargetLine1(false);
      hasTargetLine2 && setHasTargetLine2(false);
    }
    selectedNodes.forEach((node) => {
      const baseName = removeMeasureScale(node.description);
      if (shockTests?.shockTestA?.isManualInput) {
        const item = economicScenariosWithShock.find(
          (item) => item.description == baseName + '- ' + shockTests?.shockTestA?.description
        );
        const shockTestAData = getShockTestLineArray(item, selectedCalcValue);
        graphData = [...graphData, shockTestAData];
      }
      if (shockTests?.shockTestB?.isManualInput) {
        const item = economicScenariosWithShock.find(
          (item) => item.description == baseName + '- ' + shockTests?.shockTestB?.description
        );
        const shockTestBData = getShockTestLineArray(item, selectedCalcValue);
        graphData = [...graphData, shockTestBData];
      }
    });

    graphData = [
      ...graphData,
      ...selectedNodes.map((node) => [
        removeMeasureScale(node.description),
        node.year0,
        node.year1,
        node.year2,
        node.year3,
        node.year4,
        node.year5,
      ]),
    ];

    const sortedGraphData = sortGraphData(graphData);
    const resp = sortedGraphData[0].map((_, colIndex) => sortedGraphData.map((row) => row[colIndex]));
    return resp;
  };

  const handleSelectedScenarios = (value) => {
    if (value.length > 0) {
      setSelectedScenarios(value);
      props.storeEconomicScenarios({
        selectedScenarios: value.map((scenario) => Number(scenario.code)),
      });
    } else {
      NotificationManager.error(
        'Please, you must choose at least One Economic Scenario',
        'Select 1 or more scenarios',
        Constants.POP_UP_TIME
      );
    }
  };

  const handleCalcValueChange = (value) => {
    setSelectedCalcValue(value);
  };

  const getVAxisFormat = (measure) => {
    if (measure === '%') return "#,###'%'";
    if (measure === '$') return '$#,###';
    return '';
  };

  const lineStyleConfig = {
    targetLine1: { color: 'gray', lineDashStyle: [14, 4] },
    targetLine2: { color: 'gray', lineDashStyle: [3, 4] },
    'Base Case': { color: '#002060' },
    Budget: { color: '#43505E' },
    Depression: { color: '#FF0000' },
    Expansion: { color: '#16709E' },
    Overextension: { color: '#8CC94A' },
    Recession: { color: '#D6F28C' },
    Stagflation: { color: '#FF9933' },
    notUsed: { color: '#8596A8' },
  };

  const handleBaseName = (columnName) => {
    if (!columnName) return '';
    const { targetLabel, targetLabel2 } = selectedStudy.study;
    if (columnName == (targetLabel || 'Target 1')) return 'targetLine1';

    if (columnName == (targetLabel2 || 'Target 2')) return 'targetLine2';

    const wildCard = ' - ';
    return columnName.includes(wildCard) ? columnName.substr(0, columnName.indexOf(wildCard)) : columnName.trim();
  };

  const buildStyleForLine = (column) => {
    if (!column) return '';
    const baseName = handleBaseName(column);
    if (column.includes(shockTests?.shockTestA?.description)) {
      return { color: lineStyleConfig[baseName], lineDashStyle: [14, 4] };
    }
    if (column.includes(shockTests?.shockTestB?.description)) {
      return { color: lineStyleConfig[baseName], lineDashStyle: [3, 4] };
    }
    return lineStyleConfig[baseName];
  };

  const getLineStyling = () => {
    const data = getGraphData();
    let lineStyle = {};
    for (let i = 1; i < data[0].length; i++) {
      lineStyle = { ...lineStyle, [i - 1]: buildStyleForLine(data[0][i]) };
    }
    return lineStyle;
  };

  const getSelectedNodesWithShock = () => {
    const resp = [];
    const parsedShockTests = parseShockTests();
    selectedScenarios.forEach((ss) => {
      const node = selectedNodes.find((node) => node.description.startsWith(ss.name));
      if (node) {
        resp.push(node);
      }
      if (shockTests?.shockTestA?.isManualInput == true) {
        const items = parsedShockTests.filter((item) =>
          item.description.startsWith(ss.name + '- ' + shockTests?.shockTestA?.description)
        );
        resp.push(...items);
      }
      if (shockTests?.shockTestB?.isManualInput == true) {
        const items = parsedShockTests.filter((item) =>
          item.description.startsWith(ss.name + '- ' + shockTests?.shockTestB?.description)
        );
        resp.push(...items);
      }
    });
    return resp;
  };

  const CardContent = () => (
    <StyledEconomicScenarios singleColumn={displaySingleColumnLayout} id="economic-scenarios">
      <div className="economic-scenarios-header">
        <div className="main-header">
          <h2>Overview of Scenarios - Five Year Projections</h2>
          {Boolean(
            selectedNodes.length && selectedStudy.study && calcValuesOptions.length && scenarioOptions.length
          ) && (
            <div className="main-header-buttons">
              <div className="p-field-checkbox">
                <Checkbox
                  inputId="binary-relative"
                  checked={isRelativeToBudget}
                  onChange={(event) => props.dispatchIsRelativeToBudget(event.checked)}
                />
                <label htmlFor="binary-relative">Relative to budget</label>
              </div>
              {Boolean(isFullScreen) && (
                <ManagementButton action={toggleFullScreen} circle onlyIcon backIcon="pi-times" />
              )}
              {Boolean(!isFullScreen) && (
                <ManagementButton backIcon="pi pi-expand" circle onlyIcon action={toggleFullScreen} />
              )}
            </div>
          )}
        </div>
        {Boolean(calcValuesOptions.length && scenarioOptions.length) && (
          <div className="header-inputs">
            <div className="dropdown">
              <Dropdown
                appendTo="self"
                value={selectedCalcValue}
                options={calcValuesOptions}
                optionLabel="name"
                optionValue="code"
                onChange={(event) => handleCalcValueChange(event.value)}
                placeholder="Select a calculated value"
              />
            </div>
            <div className="multiselect">
              <div className="card">
                <MultiSelect
                  appendTo="self"
                  value={selectedScenarios}
                  options={scenarioOptions}
                  onChange={(event) => handleSelectedScenarios(event.value)}
                  optionLabel="name"
                  placeholder="Select a Scenario"
                  display="chip"
                  showSelectAll={false}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {Boolean(selectedNodes.length && selectedStudy.study) && (
        <>
          <div className="economic-scenarios-graph">
            <Chart
              chartType="LineChart"
              loader={<div>Loading Chart</div>}
              data={getGraphData()}
              className="es-graph"
              options={{
                height: displaySingleColumnLayout ? 700 : 600,
                chartArea: { top: 30, right: 30, bottom: 80, left: 100 },
                legend: 'bottom',
                series: getLineStyling(),
                vAxis: {
                  format: getVAxisFormat(selectedNodes[0].measure),
                },
                backgroundColor: {
                  fill: 'transparent',
                  opacity: 100,
                },
                colors: ['#002060', '#16709E', '#6ED0F7', '#8CC94A', '#D6F28C', '#F2CC73', '#43505E', '#8596A8'],
              }}
              rootProps={{ 'data-testid': '1' }}
            />
          </div>
          {Boolean(isPrinting) && (
            <div className="economic-scenarios-graph-print">
              <Chart
                chartType="LineChart"
                loader={<div>Loading Chart</div>}
                data={getGraphData()}
                options={{
                  width: 960,
                  height: 350,
                  chartArea: { top: 30, right: 10, bottom: 20, left: 80 },
                  legend: 'none',
                  series: getLineStyling(),
                  vAxis: {
                    format: getVAxisFormat(selectedNodes[0].measure),
                  },
                  backgroundColor: {
                    fill: 'transparent',
                    opacity: 100,
                  },
                  colors: ['#002080', '#16709E', '#6ED0F7', '#8CC94A', '#D6F28C', '#F2CC73', '#43505E', '#8596A8'],
                }}
                rootProps={{ 'data-testid': '1' }}
              />
              <Chart
                chartType="LineChart"
                data={getGraphData()}
                className="es-graph"
                options={{
                  height: 80,
                  chartArea: { top: 100, right: 10, bottom: 40, left: 40 },
                  legend: { position: 'top', maxLines: 5, alignment: 'center' },
                  series: getLineStyling(),
                  vAxis: {
                    format: getVAxisFormat(selectedNodes[0].measure),
                  },
                  backgroundColor: {
                    fill: 'transparent',
                    opacity: 100,
                  },
                  colors: ['#002060', '#16709E', '#6ED0F7', '#8CC94A', '#D6F28C', '#F2CC73', '#43505E', '#8596A8'],
                }}
                rootProps={{ 'data-testid': '1' }}
              />
            </div>
          )}
        </>
      )}
      {Boolean(selectedNodes.length && selectedStudy.study) && (
        <div className="economic-scenario-table">
          <TreeTable
            value={getSelectedNodesWithShock()}
            className="economic-scenarios-table"
            selectionMode="single"
            selectionKeys={selectedNodeKey}
            onSelectionChange={(event) => setSelectedNodeKey(event.value)}
            expandedKeys={expandedKeys}
            onToggle={(event) => setExpandedKeys(event.value)}
          >
            <Column
              field="scenario"
              header={scenariosDescriptionHeader}
              key={({ key }) => `scenario-economic-scenarios-${key.join('-')}`}
              body={(rowData, colBodyOptions) => economicScenariosDescriptionBody(rowData, colBodyOptions)}
              className="scenario"
              expander
              style={{ width: '360px' }}
            ></Column>
            <Column
              field="year0"
              header={selectedStudy.study.columnHead0Year}
              key={({ key }) => `year0-scenario-economic-scenarios-${key.join('-')}`}
              body={(rowData, colBodyOptions) => prettyNumber(rowData, colBodyOptions)}
            ></Column>
            <Column
              field="year1"
              header={selectedStudy.study.columnHead1Year}
              key={({ key }) => `year1-scenario-economic-scenarios-${key.join('-')}`}
              body={(rowData, colBodyOptions) => prettyNumber(rowData, colBodyOptions)}
            ></Column>
            <Column
              field="year2"
              header={selectedStudy.study.columnHead2Year}
              key={({ key }) => `year2-scenario-economic-scenarios-${key.join('-')}`}
              body={(rowData, colBodyOptions) => prettyNumber(rowData, colBodyOptions)}
            ></Column>
            <Column
              field="year3"
              header={selectedStudy.study.columnHead3Year}
              key={({ key }) => `year3-scenario-economic-scenarios-${key.join('-')}`}
              body={(rowData, colBodyOptions) => prettyNumber(rowData, colBodyOptions)}
            ></Column>
            <Column
              field="year4"
              header={selectedStudy.study.columnHead4Year}
              key={({ key }) => `year4-scenario-economic-scenarios-${key.join('-')}`}
              body={(rowData, colBodyOptions) => prettyNumber(rowData, colBodyOptions)}
            ></Column>
            <Column
              field="year5"
              header={selectedStudy.study.columnHead5Year}
              key={({ key }) => `year5-scenario-economic-scenarios-${key.join('-')}`}
              body={(rowData, colBodyOptions) => prettyNumber(rowData, colBodyOptions)}
            ></Column>
          </TreeTable>
        </div>
      )}

      {!Boolean(selectedNodes.length && selectedStudy.study && calcValuesOptions.length && scenarioOptions.length) && (
        <ManagementButton
          backIcon="pi-refresh"
          btnText="Calculate"
          action={handleCalcRiskReturn}
          id="calculate-economic-scenarios-button"
          border
        />
      )}
    </StyledEconomicScenarios>
  );

  return <DefaultBox>{CardContent()}</DefaultBox>;
};

EconomicScenarios.propTypes = {
  calcRiskReturn: PropTypes.func,
  calculatedEconomicScenarios: PropTypes.array,
  dispatchCheckStudyValidity: PropTypes.func,
  dispatchIsRelativeToBudget: PropTypes.func,
  dispatchToExportStudy: PropTypes.func,
  economicScenariosWithShock: PropTypes.array,
  isRelativeToBudget: PropTypes.bool,
  measureScale: PropTypes.object,
  printing: PropTypes.bool,
  displaySingleColumnLayout: PropTypes.bool,
  selectedStudy: PropTypes.object,
  shockTests: PropTypes.object,
  storeAUMAndReturn: PropTypes.func,
  storeEconomicScenarios: PropTypes.func,
};

const mapStateToProps = (state) => ({
  calculatedEconomicScenarios: calcEconomicScenarios(state.ui)?.economicScenarios || [],
  economicScenariosWithShock: calcEconomicScenarios(state.ui)?.economicScenariosWithShock || [],
  isRelativeToBudget: state.ui.isRelativeToBudget || false,
  measureScale: state.ui.measureScale,
  printing: state.ui.printing,
  displaySingleColumnLayout: state.ui.displaySingleColumnLayout,
  selectedStudy: state.ui.selectedStudy || {},
  shockTests: state.ui.selectedStudy.shockTests || {},
});

export default connect(mapStateToProps, {
  calcRiskReturn,
  dispatchIsRelativeToBudget,
  dispatchToExportStudy,
  dispatchCheckStudyValidity,
  storeAUMAndReturn,
  storeEconomicScenarios,
})(EconomicScenarios);
