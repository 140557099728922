import { NotificationManager } from 'react-notifications';

import { loadStudyResources, loadStudiesResources, loadStudyUsersAccessResources } from './Resources';
import { addEvent } from './EventActions';
import { Events } from '../services/Constants';
import sqlService from './../services/sqlService';
import denodoService from '../services/denodoService';
import { withLoading } from './CommonActions';
import * as types from '../actions/Types';
import Constants from '../services/Constants';

export const loadStudiesData = withLoading(() => {
  return (dispatch, getState) => {
    const {
      ui: { selectedClient },
    } = getState();
    dispatch({ type: 'RESOURCES_CURRENTSTUDYRESOURCES_SUCCESS' });
    return dispatch(loadStudiesResources(selectedClient.clientGuid));
  };
});

export const loadStudyData = withLoading((studyId) => (dispatch, getState) => {
  dispatch(addEvent(Events.OPEN_STUDY, studyId));
  dispatch({ type: types.STUDY_RESET_DATA });
  return dispatch(loadStudyResources(studyId));
});

export const deleteStudy = withLoading((id) => (dispatch, getState) => {
  dispatch(addEvent(Events.DELETE_STUDY, id));
  return sqlService()
    .deleteStudy(id)
    .then(() => {
      NotificationManager.success('Study successfully deleted', 'Success', Constants.POP_UP_TIME);
    })
    .catch(() => {
      NotificationManager.error('Unable to delete study', 'Error', Constants.POP_UP_TIME);
    });
});

export const saveStudy = withLoading(() => {
  return (dispatch, getState) => {
    const {
      ui: { selectedStudy },
    } = getState();

    return denodoService()
      .getRiskProxies(selectedStudy.study.clientGuid)
      .then(({ data }) => {
        const notAvailableMixes = [];
        const matchRiskProxy = selectedStudy.assetPools.every(({ mixName, mixId }) => {
          const mixFound = data.find((item) => {
            return item.mixId === mixId;
          });
          if (!mixFound) notAvailableMixes.push(mixName);
          return mixFound;
        });

        if (matchRiskProxy) {
          dispatch(addEvent(Events.SAVE_STUDY, selectedStudy.study.studyId));
          return sqlService()
            .saveStudyData(selectedStudy)
            .then(() => {
              dispatch({ type: types.ORIGINAL_VERSION });
              NotificationManager.success('Study successfully saved', 'Success', Constants.POP_UP_TIME);
            })
            .catch(() => {
              NotificationManager.error('Unable to save study', 'Error', Constants.POP_UP_TIME);
            });
        } else {
          return NotificationManager.error(
            `Unable to save study, ${notAvailableMixes} not available`,
            'Error',
            Constants.POP_UP_TIME
          );
        }
      });
  };
});

export const cloneStudy = withLoading((id) => (dispatch, getState) => {
  dispatch(addEvent(Events.CLONE_STUDY, id));
  return sqlService()
    .cloneStudy(id)
    .then(() => {
      NotificationManager.success('Study successfully cloned', 'Success', Constants.POP_UP_TIME);
    })
    .catch(() => {
      NotificationManager.error('Unable to clone study', 'Error', Constants.POP_UP_TIME);
    });
});

export const createNewStudy = withLoading((studyData) => {
  return (dispatch, getState) => {
    const {
      ui: { selectedClient },
      resources: {
        assumptions: { data },
      },
    } = getState();

    const lastAssumption = data.sort((a, b) => {
      return b.versionPk - a.versionPk;
    });
    const newStudyData = {
      ...studyData,
      assumptionId: lastAssumption[0].versionPk,
      clientName: selectedClient.name,
    };
    dispatch(addEvent(Events.CREATE_STUDY, newStudyData.planGuid));
    return sqlService()
      .createStudy(newStudyData)
      .then((resp) => {
        dispatch({ type: types.REDIRECT, to: `/study/${resp.data.study.studyId}/${resp.data.study.clientGuid}` });
      })
      .catch(() => {
        NotificationManager.error('Unable to create study', 'Error', Constants.POP_UP_TIME);
      });
  };
});

export const loadStudyUsersAccess = withLoading((studyId) => () => {
  return loadStudyUsersAccessResources(studyId);
});

export const saveUsersAccess = withLoading((studyId, newUsersAccess) => () => {
  return sqlService()
    .updateUsers(studyId, [...newUsersAccess])
    .then((result) => {
      if (result.data) {
        NotificationManager.success('Access updated');
      }
    });
});
