import styled from 'styled-components';
import { connect } from 'react-redux';

export const StudyStyle = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1% 1%;

  .mainToggle {
    display: inline-flex;
    max-width: 200px;
    min-width: 200px;
    white-space: nowrap;
  }

  .dropdown-menu {
    i {
      margin: 3px 6px 3px 0px;
    }
  }
`;

export const StudyHeader = styled.div`
  display: inline-flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 0 1%;
  .btn {
    --bs-btn-border-width: 0px !important;
  }
  .returnBtn {
    z-index: 100000000;
  }
  .dropdown-item:hover {
    background: #16709e;
    color: white;
  }
  a {
    color: #002060;
    font-size: 1.5em;
    text-decoration: auto;
    padding: 3px;
  }
  @media screen and (max-width: 530px) {
    display: block;
  }
`;

export const StudyTittle = styled.div`
  display: inline-flex;
  align-items: center;
  text-align: left;
  h3 {
    padding: 0 !important;
    margin: 0;
    font-size: 1.5em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
  }
  .p-button {
    font-size: 1.5rem;
  }
  @media screen and (max-width: 800px) {
    .btn-group {
      margin-left: 10px;
    }
    h3 {
      max-width: 615px;
    }
  }
  @media screen and (max-width: 730px) {
    h3 {
      max-width: 550px;
    }
  }
  @media screen and (max-width: 665px) {
    h3 {
      max-width: 485px;
    }
  }
  @media screen and (max-width: 600px) {
    h3 {
      max-width: 420px;
    }
  }
  @media screen and (max-width: 535px) {
    h3 {
      max-width: 355px;
    }
  }
  @media screen and (max-width: 470px) {
    h3 {
      max-width: 290px;
    }
  }
  @media screen and (max-width: 405px) {
    h3 {
      max-width: 225px;
    }
  }
  @media screen and (max-width: 340px) {
    h3 {
      max-width: 160px;
    }
  }
`;

export const ActionButtons = styled.div`
  display: inline-flex;
  gap: 15px;
  align-items: center;
  justify-items: flex-end;
  .p-field-checkbox {
    margin-right: 10px;
    .p-checkbox {
      margin-bottom: 2px;
      margin-right: 3px;
    }
  }
  @media screen and (max-width: 1533px) {
    .p-field-checkbox {
      display: none;
    }
  }
  @media screen and (max-width: 800px) {
    justify-content: flex-end;
    vertical-align: bottom;
  }
  @media screen and (max-width: 425px) {
    display: flex;
    flex-direction: row;
    text-align: center;
  }
`;

export const StyledBoxes = styled.div`
  display: inline-flex;
  max-width: 100vw;
  padding: 0 1%;
  gap: 15px;
  .CashFlows {
    flex: 20vw;
  }
  .PoolDesc {
  }
  .AssetAllocation {
    padding-top: 20px;
    min-height: 30vh;
    overflow-x: auto;
  }
  .ProjectAUM {
    min-height: 40vh;
  }
  @media screen and (max-width: ${(props) => (props.displaySingleColumnLayout ? '999999px' : '1533px')}) {
    flex-direction: column;
  }
`;

const StyledBoxInput = styled.div`
  width: 50%;
  .asset-pool-table,
  .cash-flows-table,
  .daily-expense-factor-table {
    .p-datatable-responsive-scroll > .p-datatable-wrapper {
      overflow-x: unset;
    }
    overflow-x: auto;
    .p-datatable-table,
    .p-treetable {
      width: 100%;
      min-width: 700px;
    }
  }
  @media screen and (max-width: ${(props) => (props.displaySingleColumnLayout ? '999999px' : '1533px')}) {
    width: 100%;
  }
  @media screen and (max-width: 425px) {
    .asset-pool-header,
    .cash-flows-header,
    .daily-expense-factor-header {
      display: flex;
      flex-direction: column;
      text-align: center;
      .p-button {
        max-width: unset;
        width: fill-available;
      }
    }
  }
`;

const StyledBoxDisplay = styled.div`
  width: 50%;
  .asset-allocations-table,
  .economic-scenarios-graph,
  .economic-scenario-table {
    overflow-x: auto;
    .es-graph {
      width: 100%;
      min-width: 700px;
    }
    .p-treetable {
      min-width: 800px;
    }
    th.scenario {
      padding-left: 5px;
      .btn {
        padding: 6px 0px;
      }
      span {
        margin-left: 2px;
      }
    }
  }
  @media screen and (max-width: ${(props) => (props.displaySingleColumnLayout ? '999999px' : '1533px')}) {
    width: 100%;
    .economic-scenarios-header {
      display: block;
      .header-inputs {
        flex-direction: column;
      }
      .multiselect {
        margin-top: 1rem;
        margin-right: unset;
        margin-left: unset;
        .p-multiselect {
          min-width: unset;
        }
      }
      .p-dropdown {
        width: 100%;
        width: -moz-available; /* WebKit-based browsers will ignore this. */
        width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
      }
    }
  }
  @media screen and (max-width: 425px) {
    .economic-scenarios-header {
      display: flex;
      flex-direction: column;
      text-align: center;
      .header-inputs {
        .p-button,
        .dropdown,
        .multiselect {
          max-width: unset;
          width: fill-available;
          margin-left: 0px;
          margin-right: 0px;
        }
      }
    }
  }
`;

export const ToggleText = styled.div`
  display: inline-flex;
  align-content: center;
  i {
    color: #002060;
    font-size: 1em;
    padding: 8px;
  }
`;

export const LastCalculation = styled.span`
  margin-top: 9px;
  font-size: 12px;
  font-weight: 100;
  @media screen and (max-width: 800px) {
    margin-top: 0px;
  }
`;

const mapStateToProps = (state) => ({
  displaySingleColumnLayout: state.ui.displaySingleColumnLayout,
});

export const Boxes = connect(mapStateToProps)(StyledBoxes);
export const BoxInput = connect(mapStateToProps)(StyledBoxInput);
export const BoxDisplay = connect(mapStateToProps)(StyledBoxDisplay);
